import React, { Component, createRef } from "react";
import { Form, Col, Row } from "react-bootstrap";
import "whatwg-fetch";
import { Card, CardHeader, CardBody } from "reactstrap";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import ReactLoading from "react-loading";
import muiComma from "./commaFunction";
import { forwardRef } from "react";
import {
  Grid,
  Container,
  Typography,
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@mui/icons-material/Add";

// ServerURL
import url from "src/serverURL.js";

import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import PDF from "./pdf";
import { PDFViewer } from "@react-pdf/renderer";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import EditReceipt from "./editReceiptComponent";
import CreateReceipt from "./createReceiptComponent";
import ViewReceipt from "./viewReceiptImage";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Print from "@mui/icons-material/Print";
import MaterialTable from "material-table";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward style={{ color: "#fff", opacity: 1 }} {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

class ReceiptManage extends Component {
  constructor() {
    super();
    this.state = {
      rows: [],
      call: () => {
        var columns = this.state.columns;
        var rows = this.state.rows;
        return { columns, rows };
      },
      id: 0,
      cash_accounts: [],
      bank_accounts: [],
      date_from: new Date().setMonth(new Date().getMonth() - 1),
      date_to: new Date(),
      final_flag: "",
      total_balance: 0,
      cash: true,
      bank: false,
      load: false,
      type: "customer",
      ac_title_data: null,
      all_accounts: {},
      original_record: [],
      body: "",
      title: "",
      opening_balance: 0,
      opening_flag: "",
      add: false,
      date: new Date(),
      amount: 0,
      discount: 0,
      net_cost: 0,
      submit: true,
      editFlag: false,
      viewFlag: false,
      editData: {},
      viewData: {},
      createFlag: false,
      invoice: {},
      printFlag: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.Reload = this.Reload.bind(this);
    this.downloadCsv = this.downloadCsv.bind(this);
    this.tableRef = createRef();
  }

  downloadCsv(data, fileName){
    const finalFileName = fileName.endsWith(".csv")
      ? fileName
      : `${fileName}.csv`;
    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
    a.setAttribute("download", finalFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  fetchData = () => {
    var from = new Date(this.state.date_from);
    from.setHours(0);
    var to = new Date(this.state.date_to);
    to.setHours(24);
    var data = {
      from: from,
      to: to,
      ac_id: this.state.ac_title_data,
    };

    fetch(url + "inventry/receipt/report", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())

      .then((transactions) => {
        this.setState({ rows: transactions });
        var amount = 0;

        transactions.map((trans) => {
          amount = amount + trans.amount;
        });
        this.setState({ amount: amount });
        this.setState({ submit: false });
      });
  };

  componentDidMount() {
    this.fetchData();
  }
  componentWillMount() {
    fetch(url + "inventry/jv/accounts", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status == 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((client) => {
        console.log("Clients Avaible", client);
        var cli = [];
        var accounts = {};
        client.map((acc) => {
          if (acc.type == "customer" || acc.type == "vendor") {
            cli.push(acc.name);
            var obj = {
              title: acc.name,
              type: acc.type,
              id: acc.id,
            };
            accounts[acc.name] = obj;
          } else {
            cli.push(acc.title);
            var obj = {
              title: acc.title,
              type: acc.type,
              id: acc.id,
            };
            accounts[acc.title] = obj;
          }
        });
        this.setState({ all_accounts: accounts });
        this.setState({ load: true });
        this.setState({ options: cli });
      });
  }
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  onSubmit(e) {
    this.setState({ submit: true });
    // Validate date range
    const fromDate = new Date(this.state.date_from);
    const toDate = new Date(this.state.date_to);

    if (fromDate > toDate) {
      // Display an alert or handle the validation error accordingly
      alert("Date To cannot be earlier than Date From");
      return;
    }

    e.preventDefault();
    this.fetchData();
  }
  Clear_Text = () => {
    try {
      const instance = this._typeahead_1.getInstance();
      instance.clear();
    } catch (e) {
      console.log(e);
    }
  };
  Accounts = () => {
    const truncateText = (text, maxChars) => {
      if (text.length > maxChars) {
        return text.slice(0, maxChars) + " ...";
      } else {
        return text;
      }
    };
    return (
      <div>
        <Autocomplete
          getOptionLabel={(option) => truncateText(option, 20)} // Truncating the option label
          caseSensitive={false}
          id="filtering-example"
          ignoreDiacritics={true}
          ref={(ref) => (this._typeahead_1 = ref)}
          onChange={(event, newValue) => {
            var id = this.state.all_accounts[newValue];
            console.log(id);
            this.setState({ ac_title_data: id });
          }}
          clearButton={true}
          options={this.state.options}
          placeholder="A/C Title..."
          size="small"
          renderInput={(params) => <TextField {...params} label="Search" />}
        />
      </div>
    );
  };
  getData() {
    var columns = this.state.columns;
    var rows = this.state.rows;
    console.log("Receipt is:", rows);
    var filter = this.original;
    return { columns, rows };
  }
  handleChangeFrom = (date) => {
    this.setState({
      date_from: date,
    });
  };
  handleChangeTo = (date) => {
    this.setState({
      date_to: date,
    });
  };
  editClose = () => {
    this.setState({ editFlag: false });
    // this.Reload();
  };
  viewClose = () => {
    this.setState({ viewFlag: false });
  };
  createClose = () => {
    console.log("Receipt Create close function is called.");
    this.setState({ createFlag: false });
    // this.Reload();
  };
  Reload = () => {
    console.log("Receipt Reload function is called.");
    this.fetchData();
  };

  truncateText = (text, maxChars) => {
    if (text.length > maxChars) {
      return text.slice(0, maxChars) + " ...";
    } else {
      return text;
    }
  };
  render() {
    return (
      <Row>
        <Grid item md={12}>
          {this.state.editFlag ? (
            <EditReceipt
              editClose={this.editClose}
              editData={this.state.editData}
              Reload={this.Reload}
            />
          ) : null}
          {this.state.createFlag ? (
            <CreateReceipt
              createClose={this.createClose}
              Reload={this.Reload}
            />
          ) : null}
          {this.state.viewFlag ? (
            <ViewReceipt
              viewClose={this.viewClose}
              viewData={this.state.viewData}
            />
          ) : null}
          <Card>
            <CardHeader></CardHeader>
            <CardBody className="all-icons">
              <Dialog
                open={this.state.printFlag}
                onClose={() => this.setState({ printFlag: false })}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <DialogTitle id="dialog-title">Receipt</DialogTitle>
                <DialogContent>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <PDFViewer height="1000" width="100%" className="app">
                      <PDF
                        accountTitle={this.state.invoice.ac_title}
                        invoiceNo={this.state.invoice.id}
                        paymentMethod={this.state.invoice.payment_method}
                        paymentTitle={this.state.invoice.payment_title}
                        advanceTax={this.state.invoice.advance_tax}
                        chequeNo={this.state.invoice.cheque_no}
                        totalAmount={this.state.invoice.amount}
                        paymentId={this.state.invoice.payment_id}
                        date={this.state.invoice.date}
                        address={this.state.invoice.address}
                        client={this.state.invoice.client}
                        remarks={this.state.invoice.remarks}
                      />
                    </PDFViewer>
                  </Typography>
                </DialogContent>
              </Dialog>

              <Form onSubmit={this.onSubmit} className="Notifications">
                <Grid
                  container
                  controlId="exampleForm.ControlSelect2"
                  spacing={2}
                >
                  <Grid item sm={3}>
                    <br />
                    <Button
                      variant="contained"
                      size="md"
                      block
                      onClick={() => {
                        this.setState({ createFlag: true });
                      }}
                      startIcon={<AddIcon />}
                    >
                      Create Receipt
                    </Button>
                  </Grid>
                  <Grid item sm={1}></Grid>
                  <Grid item sm={2}>
                    <b>From :</b>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        value={this.state.date_from}
                        maxDate={this.state.date_to}
                        onChange={this.handleChangeFrom}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item sm={2}>
                    <b>To :</b>
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        value={this.state.date_to}
                        minDate={this.state.date_from}
                        maxDate={new Date()}
                        onChange={this.handleChangeTo}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item sm={3}>
                    <b>A/C Title:</b>

                    {this.Accounts()}
                  </Grid>
                  <Grid item sm={1}>
                    <br />
                    <Button
                      variant="contained"
                      size="md"
                      block
                      type="submit"
                      disabled={this.state.date_from > this.state.date_to}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Form>
              <Grid
                container
                style={{ marginTop: "1em" }}
                controlId="formHorizontalEmail"
              >
                <Grid item md={12}>
                  <MaterialTable
                    title="All Receipt Records"
                    icons={tableIcons}
                    tableRef={this.tableRef}
                    columns={[
                      {
                        title: "Date",
                        field: "date",
                        type: "date",
                      },
                      {
                        title: "Receipt No.",
                        field: "id",
                      },
                      {
                        title: " Sale Invoice No.",
                        field: "sale_invoiceno",
                        cellStyle: {
                          color: "blue",
                          fontWeight: "bold",
                        },
                      },
                      {
                        title: "A/C Title",
                        field: "ac_title",
                        render: (rowData) => {
                          const truncatedAcTitlw = this.truncateText(
                            rowData.ac_title,
                            18
                          );
                          return (
                            <Tooltip
                              title={
                                <Typography fontSize="1.5em">
                                  {rowData.ac_title}
                                </Typography>
                              }
                            >
                              <span>{truncatedAcTitlw}</span>
                            </Tooltip>
                          );
                        },
                      },
                      {
                        title: "Remarks",
                        field: "remarks",
                        render: (rowData) => {
                          const truncatedRemarks = this.truncateText(
                            rowData.remarks,
                            18
                          );
                          return (
                            <Tooltip
                              title={
                                <Typography fontSize="1.5em">
                                  {rowData.remarks}
                                </Typography>
                              }
                            >
                              <span>{truncatedRemarks}</span>
                            </Tooltip>
                          );
                        },
                      },
                      {
                        title: "Receiving Title",
                        field: "payment_title",
                        render: (rowData) => {
                          const truncatedClientName = this.truncateText(
                            rowData.payment_title,
                            20
                          );
                          return (
                            <Tooltip
                              title={
                                <Typography fontSize="1.5em">
                                  {rowData.payment_title}
                                </Typography>
                              }
                            >
                              <span>{truncatedClientName}</span>
                            </Tooltip>
                          );
                        },
                      },
                      {
                        title: "Receiving Method",
                        field: "payment_method",
                      },
                      {
                        title: "Amount",
                        field: "amount",
                        render: (rowData) => muiComma(rowData.amount),
                      },
                    ]}
                    isLoading={this.state.submit}
                    data={this.state.rows}
                    options={{
                      headerStyle: {
                        backgroundColor: "#01579b",
                        color: "#FFF",
                        whiteSpace: "nowrap",
                        fontSize: "0.8em",
                      },
                      
                      exportCsv: (columns, data) => {
                        // Turn headers into array of strings
                        const headerRow = columns.map((col) => {
                          if (typeof col.title === "object") {
                            // I am not sure what props the Translate component exposes
                            // but you would need to change `text` in `col.title.props.text`
                            // to whatever prop you need.
                            return col.title.props.text;
                          }
                          return col.title;
                        });
            
                        // Turn data into an array of string arrays, without the `tableData` prop
            
                        const dataRows = data.map(({ tableData, ...row }) => {
                         
            
                          const d = new Date(row.date);
                          let mm = d.getMonth()+1;
                          let dd = d.getDate();
                          let yyyy = d.getFullYear();
            
                          row = {
                            date: dd + "/" + mm + "/" + yyyy,
                            id:row.id,
                            sale_invoiceno:row.sale_invoiceno,
                            ac_title:row.ac_title,
                            remarks:(row.remarks).replace(/,/g, ''),
                            payment_title:row.payment_title,
                            payment_method:row.payment_method,
                            amount:(row.amount+"").replace(/,/g, '')
                          };
            
                          return Object.values(row);
                        });
            
                        // Aggregate header data and 'body' data
                        // Mirror default export behavior by joining data via
                        // the delimiter specified within material table (by default comma delimited)
                        const { exportDelimiter } = this.tableRef.current.props.options;
                        const delimiter = exportDelimiter ? exportDelimiter : ",";
                        const csvContent = [headerRow, ...dataRows]
                          .map((e) => e.join(delimiter))
                          .join("\n");
            
                        // This mirrors the default export behavior where the
                        // exported file name is the table title.
                        const csvFileName = this.tableRef.current.props.title;
            
                        // Allow user to download file as .csv
                        this.downloadCsv(csvContent, csvFileName);
                      },
                    

                      rowStyle: (rowData, index) => ({
                        backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#FFF",
                        height: "5em",
                        fontSize: "0.8em",
                      }),

                      actionsColumnIndex: -1,
                      sorting: true,
                      search: true,
                      paging: true,
                      pageSize: 10,
                      pageSizeOptions: false,
                      showFirstLastPageButtons: false,
                      exportAllData: true,
                      exportButton: true,
                    }}
                    actions={[
                      {
                        icon: () => <VisibilityIcon style={{ padding: 0 }} />,
                        tooltip: "View Receipt Image",
                        onClick: (event, rowData) => {
                          console.log("View");
                          this.setState({ viewData: rowData });
                          this.setState({ viewFlag: true });
                        },
                      },
                      {
                        icon: () => <Edit style={{ padding: 0 }} />,
                        tooltip: "Edit",
                        onClick: (event, rowData) => {
                          console.log("Edit");
                          this.setState({ editData: rowData });
                          this.setState({ editFlag: true });
                        },
                      },
                      {
                        icon: () => <Print style={{ padding: 0 }} />,
                        tooltip: "Print",
                        onClick: (event, rowData) => {
                          console.log(rowData);
                          console.log("Clients are", this.state.invoice.client);
                          this.setState({ invoice: rowData });
                          this.setState({ printFlag: true });
                        },
                      },
                      {
                        icon: () => <DeleteOutline style={{ padding: 0 }} />,
                        tooltip: "Delete",
                        onClick: (event, rowData) => {
                          if (
                            window.confirm(
                              "Are you sure to delete this receipt ?"
                            ) == true
                          ) {
                            var data = {
                              id: rowData.id,
                            };

                            fetch(url + "inventry/receipt", {
                              method: "DELETE",
                              headers: {
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify(data),
                            }).then((res) => {
                              if (res.status == 200) {
                                alert("Deleted");
                                this.Reload();
                              } else {
                                alert("Something went wrong!");
                              }
                            });
                          }
                        },
                      },
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "0.5em" }}>
                <Grid item sm={6}></Grid>
                <Grid
                  item
                  sm={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                ></Grid>
                <Grid
                  item
                  sm={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                ></Grid>
                <Grid
                  item
                  sm={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <b class="Total Profit">Total Amount</b>
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    type="text"
                    value={this.numberWithCommas(this.state.amount.toFixed(2))}
                    style={{
                      fontWeight: "bold",
                    }}
                    required
                    placeholder="Total Amount..."
                  />
                </Grid>
              </Grid>

              <Modal
                open={this.state.alert}
                onClose={() => this.setState({ alert: false })}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {this.state.title}
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {this.state.body}
                  </Typography>
                </Box>
              </Modal>
            </CardBody>
          </Card>
        </Grid>
      </Row>
    );
  }
}
export default ReceiptManage;
