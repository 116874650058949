import React, { Component } from "react";
import { Card, CardHeader, CardBody, Input } from "reactstrap";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import { Form, Col, Row } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import ReactLoading from "react-loading";
import { forwardRef } from "react";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import MaterialTable from "material-table";
import { Grid, Container, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import url from "../../serverURL.js";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward style={{ color: "#fff", opacity: 1 }} {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class JV extends React.Component {
  constructor() {
    super();
    this.state = {
      columns: [
        {
          label: "Account Title",
          field: "account_title",
          sort: "asc",
        },
        {
          label: "Cheque No",
          field: "cheque",
          sort: "asc",
        },
        {
          label: "Debit",
          field: "debit",
          sort: "asc",
        },
        {
          label: "Credit",
          field: "credit",
          sort: "asc",
        },
        {
          label: "Description",
          field: "description",
          sort: "asc",
        },
        {
          label: "Remove",
          field: "remove",
          sort: "asc",
        },
      ],
      rows: [],
      call: () => {
        var columns = this.state.columns;
        var rows = this.state.rows;
        return { columns, rows };
      },
      id: 0,
      options: [],
      description: "",
      name: "",
      cheque: 0,
      mobile: 0,
      advance_tax: 0,
      email: "",
      cash_accounts: [],
      bank_accounts: [],
      cash: true,
      bank: false,
      ntn: 0,
      debit: 0,
      credit: 0,
      JvNo: "",
      stn: 0,
      address: "",
      type: "customer",
      ac_title_data: null,
      all_accounts: {},
      amount: 0,
      debit: 0,
      credit: 0,
      remarks: "",
      original_record: [],
      category: "",
      categories: [],
      add: false,
      title: "",
      total_credit: 0,
      total_debit: 0,
      body: "",
      date: new Date(),
      extension: "",
      file: "",
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.numberWithCommas = this.numberWithCommas.bind(this);
    this.Reload = this.Reload.bind(this);
    this.ReCalculate = this.ReCalculate.bind(this);
  }
  Reload() {}
  handleChange = (date) => {
    this.setState({
      date: date,
    });
  };
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  componentWillMount() {
    fetch(url + "inventry/jv/JVNo", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status == 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((counter) => {
        this.setState({ JvNo: counter.JvNo });
      });
    fetch(url + "inventry/jv/accounts", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status == 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((client) => {
        var cli = [];
        var accounts = {};
        client.map((acc) => {
          if (acc.type == "customer" || acc.type == "vendor") {
            cli.push(acc.name);
            var obj = {
              title: acc.name,
              type: acc.type,
              id: acc.id,
            };
            accounts[acc.name] = obj;
          } else {
            if (
              ((acc.id + "").includes("O-1") &&
                (acc.id + "").split("-").length == 3) ||
              acc.type == "Expenses" ||
              acc.type == "Long Term Liabilities" ||
              acc.type == "Current Liabilities" ||
              acc.type == "Revenue" ||
              acc.type == "Assets" ||
              acc.title == "Cash Sales" ||
              acc.title == "Credit Sales" ||
              acc.title == "Cost of Sales" ||
              acc.title == "Inventory"
            ) {
              return;
            }
            cli.push(acc.title);
            var obj = {
              title: acc.title,
              type: acc.type,
              id: acc.id,
            };
            accounts[acc.title] = obj;
          }
        });
        console.log(accounts);
        this.setState({ all_accounts: accounts });
        this.setState({ options: cli });
      });
  }
  componentWillReceiveProps() {
    this.Reload();
  }
  onSubmit(e) {
    e.preventDefault();

    if (this.state.ac_title_data == null) {
      this.setState({ title: "A/C Title" });
      this.setState({ body: "Kindly Select A/C for Transaction!!" });
      this.setState({ add: true });
      return;
    }
    if (this.state.ac_title_data.type == "BANK" && this.state.cheque == 0) {
      this.setState({ title: "Cheque" });
      this.setState({ body: "Enter Cheque Number!!" });
      this.setState({ add: true });
      return;
    }
    if (this.state.credit <= 0 && this.state.debit <= 0) {
      this.setState({ title: "Alert" });
      this.setState({ body: "Kindly Enter Credit or Debit !!" });
      this.setState({ add: true });
      return;
    }
    if (this.state.credit < 0 || this.state.debit < 0) {
      this.setState({ title: "Alert" });
      this.setState({ body: "Invalid Credit or Debit !!" });
      this.setState({ add: true });
      return;
    }

    var data = {
      details: {
        credit: this.state.credit,
        debit: this.state.debit,
        ac_title: this.state.ac_title_data.title,
        ac_id: this.state.ac_title_data.id,
        ac_type: this.state.ac_title_data.type,
        cheque: this.state.cheque,
        description: this.state.description,
      },
      account_title: this.state.ac_title_data.title,
      cheque: this.state.cheque,
      credit: (this.state.credit),
      debit: (this.state.debit),
      description: this.state.description,
      remove: (
        <button
          className="btn btn-danger"
          onClick={() => {
            var index = this.state.rows.indexOf(data);
            var temp = this.state.rows;
            if (data.details.credit > 0) {
              var total_credit =
                parseInt(this.state.total_credit) -
                parseInt(data.details.credit);
              this.setState({ total_credit: total_credit });
            } else {
              var total_debit =
                parseInt(this.state.total_debit) - parseInt(data.details.debit);
              this.setState({ total_debit: total_debit });
            }

            temp.splice(index, 1);
            this.setState({ rows: temp });
          }}
        >
          Remove
        </button>
      ),
    };
    var temp = this.state.rows;
    temp.push(data);
    console.log(temp);
    var total_credit =
      parseInt(this.state.total_credit) + parseInt(this.state.credit);
    var total_debit =
      parseInt(this.state.total_debit) + parseInt(this.state.debit);
    this.setState({ total_credit: total_credit });
    this.setState({ total_debit: total_debit });
    this.setState({ rows: temp });
    this.setState({ description: "" });
    this.setState({ credit: 0 });
    this.setState({ debit: 0 });
    this.setState({ cheque: 0 });
    // this.Clear_Text()
  }
  Clear_Text = () => {
    try {
      const instance = this._typeahead_1.getInstance();
      instance.clear();
    } catch (e) {
      console.log(e);
    }
  };
  SaveJv = () => {
    if (this.state.total_credit === 0 || this.state.total_debit === 0) {
      this.setState({ title: "JV" });
      this.setState({ body: "Credit & Debit must be greater than 0." });
      this.setState({ add: true });
      return;
    }

    if (this.state.total_credit != this.state.total_debit) {
      this.setState({ title: "JV" });
      this.setState({ body: "Credit & Debit must be equal !!" });
      this.setState({ add: true });
      return;
    }
    var transactions = [];
    var temp = this.state.rows;
    temp.map((item) => {
      transactions.push(item.details);
    });
    var data = {
      id: this.state.JvNo,
      transactions: transactions,
      date: this.state.date,
      credit: this.state.total_credit,
      debit: this.state.total_debit,
      remarks: this.state.remarks,
      userId: localStorage.getItem("id"),
      extension: this.state.extension,
      file: this.state.file,
    };

    var JvNoID = this.state.JvNo;

    fetch(url + "inventry/jv", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status == 400) {
        this.setState({ title: "JV" });
        this.setState({ body: "JV Record Failed!!" });
        this.setState({ add: true });
      } else {
        console.log("Response JSON", data);
        console.log("Extension:", this.state.extension);
        console.log("Base64 Data:", this.state.file);
        this.setState({ total_credit: 0 });
        this.setState({ total_debit: 0 });
        this.setState({ remarks: 0 });
        this.setState({ rows: [] });
        this.setState({ title: "JV " + JvNoID });
        this.setState({ body: "JV Record Added!!" });
        this.setState({ add: true });
        setTimeout(() => {
          this.props.handleClose();
        }, 2000);
        fetch(url + "inventry/jv/JVNo", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            if (res.status == 400) {
              alert("No Category Found");
            } else {
              return res.json();
            }
          })
          .then((counter) => {
            this.setState({ JvNo: counter.JvNo });
          });
      }
    });
  };
  Accounts = () => {
    const truncateText = (text, maxChars) => {
      if (text.length > maxChars) {
        return text.slice(0, maxChars) + " ...";
      } else {
        return text;
      }
    };
    return (
      <div>
        <Autocomplete
          caseSensitive={false}
          id="ac_title"
          ignoreDiacritics={true}
          ref={(ref) => (this._typeahead_1 = ref)}
          onChange={(event, text) => {
            var text = text + "";
            var id = this.state.all_accounts[text];
            console.log(id);
            this.setState({ ac_title_data: id });
          }}
          size="small"
          options={this.state.options}
          getOptionLabel={(option) => truncateText(option, 20)} // Truncating the option label
          placeholder="A/C Title..."
          renderInput={(params) => (
            <TextField size="small" fullWidth {...params} label="Search" />
          )}
        />
      </div>
    );
  };
  getData() {
    var columns = this.state.columns;
    var rows = this.state.rows;
    var filter = this.original;
    return { columns, rows };
  }

  handlePicture = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.error("No file selected");
      return;
    }

    const maxSize = 5 * 1024 * 1024;
    if (file.size > maxSize) {
      alert("File size exceeds 5 MB. Please choose a smaller file.");
      event.target.value = "";
      return;
    }

    const fileInput = document.getElementById("file-upload");
    if (fileInput) {
      const filePath = fileInput.value;
      const fileArr = filePath.split(".");
      const ext = fileArr[fileArr.length - 1];

      const base64 = await this.convertBase64(file);
      this.setState({
        extension: ext,
        file: base64,
      });
    } else {
      console.error("Element with ID 'file-upload' not found");
    }
  };

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // ->> Recalculate

  async ReCalculate(objArr) {
    var totalDebit = 0;
    var totalCredit = 0;

    objArr.forEach((rowData) => {
      console.log("Rowdata", rowData);
      const debit = parseFloat(rowData.debit) || 0;
      const credit = parseFloat(rowData.credit) || 0;

      totalDebit += debit;
      totalCredit += credit;
    });

    console.log("Total Debit:", totalDebit);
    console.log("Total Credit:", totalCredit);

    // Update state directly within the function
    this.setState({
      total_debit: totalDebit,
      total_credit: totalCredit,
    });
  }

  // async ReCalculate(objArr) {
  //   var debit = 0;
  //   var credit = 0;
  //   console.log("Recalculate Called", objArr[0]);

  // var total_gst = 0;
  // var total_discount = 0;

  // const dataArr = [...objArr];

  // var res = await dataArr.map((item) => {
  //   var amt = parseFloat(
  //     (parseFloat(item.rate) * parseInt(item.quantity)).toFixed(2)
  //   );

  //   var AfterDiscount = amt - item.discount;

  //   total_discount = item.discount + total_discount;

  //   var gst = item.gst;

  //   var amount_gst = (AfterDiscount / 100) * gst;

  //   item.net_cost = parseFloat((amount_gst + AfterDiscount).toFixed(2));

  //   item.amount = amt;

  //   total_gst = total_gst + amount_gst;

  //   amount = parseFloat(amt) + amount;

  //   return amount;
  // });

  // Promise.all(res).then((values) => {
  //   this.setState({ gst_price: total_gst });
  //   this.setState({ net_value: amount + total_gst });
  //   this.setState({ payable: amount + total_gst - total_discount });
  //   this.setState({ amount: amount });
  //   this.setState({ discount: total_discount });
  // });
  // }

  render() {
    const { handleClose } = this.props;
    return (
      <>
        <Grid container>
          <Grid
            item
            sm={12}
            style={{
              height: "10vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AppBar
              // position="static"
              style={{
                backgroundColor: "#01579b",
                color: "white",
              }}
            >
              <Toolbar>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      value={this.state.date}
                      onChange={(date) => {
                        this.setState({ date: date });
                      }}
                      maxDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          inputProps={{
                            style: {
                              height: `0.9em`,
                            },
                          }}
                          style={{
                            borderColor: "#fff",
                            border: "1px solid white",
                            borderRadius: 5,
                          }}
                          sx={{
                            svg: { color: "#fff" },
                            input: { color: "#fff" },
                          }}
                          size="small"
                          InputProps={{}}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>

                <Typography
                  variant="h6"
                  style={{ flexGrow: 1, textAlign: "center" }}
                >
                  Create JV
                </Typography>
                <Button
                  color="inherit"
                  style={{ borderColor: "#fff", border: "1px solid white" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Toolbar>
            </AppBar>
          </Grid>

          <Grid container sm={12} style={{ height: "89vh" }}>
            <Grid item sm={9} style={{ paddingLeft: "1em" }}>
              <MaterialTable
                style={{ zoom: "70%" }}
                title=""
                icons={tableIcons}
                columns={[
                  {
                    title: "Account Title",
                    field: "account_title",
                    editable: false,
                  },
                  {
                    title: "Cheque No.",
                    field: "cheque",
                  },
                  {
                    title: "Debit",
                    field: "debit",
                    render: rowData => <b>{this.numberWithCommas(rowData.debit)}</b>,
                    cellStyle: (rowData) => ({
                      backgroundColor: "#54D62C",
                      color: "white",
                    }),
                  },
                  {
                    title: "Credit",
                    field: "credit",
                    render: rowData => <b>{this.numberWithCommas(rowData.credit)}</b>,

                    cellStyle: (rowData) => ({
                      backgroundColor: "#FF4842",
                      color: "white",
                    }),
                  },
                  {
                    title: "Description",
                    field: "description",
                  },
                  // {
                  //   title: "Remove",
                  //   field: "remove",
                  //   render: (rowData) => (
                  //     <DeleteOutline
                  //       color="secondary"
                  //       onClick={() => {
                  //         const dataDelete = [...this.state.rows];
                  //         const index = rowData.tableData.id;
                  //         dataDelete.splice(index, 1);
                  //         this.setState({ rows: [...dataDelete] });
                  //         if (parseFloat(rowData.details.credit) > 0) {
                  //           var total_credit =
                  //             parseFloat(this.state.total_credit) -
                  //             parseFloat(rowData.details.credit);
                  //           this.setState({
                  //             total_credit: total_credit,
                  //           });
                  //         } else {
                  //           var total_debit =
                  //             parseFloat(this.state.total_debit) -
                  //             parseFloat(rowData.details.debit);
                  //           this.setState({ total_debit: total_debit });
                  //         }
                  //       }}
                  //     />
                  //   ),
                  // },
                ]}
                data={this.state.rows}
                options={{
                  headerStyle: {
                    backgroundColor: "#01579b",
                    color: "#FFF",
                    whiteSpace: "nowrap",
                  },
                  rowStyle: (rowData, index) => ({
                    backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#FFF",
                  }),

                  actionsColumnIndex: -1,
                  paging: true,
                  pageSize: 10,
                  pageSizeOptions: false,
                  showFirstLastPageButtons: false,
                  maxBodyHeight: "850px",
                  selection: true,
                  search: false,
                }}
                actions={[
                  {
                    tooltip: "Remove All Selected JV",
                    icon: () => <DeleteOutline />,
                    onClick: (evt, data) => {
                      const confirmDelete = window.confirm("Delete all JV ?");
                      if (confirmDelete) {
                        let _data = [...this.state.rows];
                        data.forEach((rd) => {
                          _data = _data.filter(
                            (t) => t.tableData.id !== rd.tableData.id
                          );
                        });
                        this.setState({ rows: [..._data] });
                        this.ReCalculate([..._data]);
                      }
                    },
                  },
                ]}
                editable={{
                  
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataUpdate = [...this.state.rows];

                        const index = oldData.tableData.id;
                        // Update
                        if (newData.cheque == "" || isNaN(newData.cheque)) {
                          newData.cheque = 0;
                        }
                        if (newData.debit == "" || isNaN(newData.debit)) {
                          newData.debit = 0;
                        }
                        if (newData.credit == "" || isNaN(newData.credit)) {
                          newData.credit = 0;
                        }
                        console.log(dataUpdate)

                        dataUpdate[index] = newData;
                        this.setState({ rows: [...dataUpdate] });
                        this.ReCalculate([...dataUpdate]);
                        resolve();
                      }, 1000);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataDelete = [...this.state.rows];
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        this.setState({ rows: [...dataDelete] });
                        this.ReCalculate([...dataDelete]);
                        resolve();
                      }, 1000);
                    }),

                  // onRowDelete: (oldData) =>
                  //   new Promise((resolve, reject) => {
                  //     setTimeout(() => {
                  //       const dataDelete = [...this.state.rows];
                  //       const index = oldData.tableData.id;
                  //       // Delete
                  //       if (parseFloat(oldData.details.credit) > 0) {
                  //         var total_credit =
                  //           parseFloat(this.state.total_credit) -
                  //           parseFloat(oldData.details.credit);
                  //         this.setState({
                  //           total_credit: total_credit,
                  //         });
                  //       } else {
                  //         var total_debit =
                  //           parseFloat(this.state.total_debit) -
                  //           parseFloat(oldData.details.debit);
                  //         this.setState({ total_debit: total_debit });
                  //       }

                  //       dataDelete.splice(index, 1);
                  //       this.setState({ rows: [...dataDelete] });

                  //       resolve();
                  //     }, 600);
                  // }),
                }}
              />

              <Grid
                style={{ marginTop: "1em", height: "5vh", paddingLeft: "1em" }}
                container
                spacing={2}
              >
                <Grid sm={6}></Grid>
                <Grid sm={3}>
                  <b class="debit" style={{ color: "green" }}>
                    Total Debit
                  </b>
                  <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    value={this.numberWithCommas(this.state.total_debit)}
                    class="debit form-control"
                    placeholder="Debit..."
                    size="small"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid sm={3}>
                  <b class="credit" style={{ color: "red", marginLeft: 10 }}>
                    Total Credit
                  </b>
                  <TextField
                    style={{ marginLeft: 10 }}
                    size="small"
                    fullWidth
                    required
                    id="outlined-basic"
                    variant="outlined"
                    value={this.numberWithCommas(this.state.total_credit)}
                    class="credit form-control"
                    placeholder="Credit..."
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={3}
              style={{ paddingLeft: "1em", paddingRight: "1.6em" }}
            >
              <Grid item style={{ height: "10vh" }} sm={12}>
                <b style={{ fontSize: "0.9em" }}>JV No.</b>
                <div>
                  <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    value={this.state.JvNo}
                    disabled
                    fullWidth
                    size="small"
                  />
                </div>
              </Grid>

              <Grid item sm={12} style={{ height: "10vh" }}>
                <b style={{ fontSize: "0.9em" }}>A/C Title:</b>
                {this.Accounts()}
              </Grid>
              <Grid item sm={12} style={{ height: "10vh" }}>
                <b style={{ fontSize: "0.9em" }}>Description</b>
                <br />
                <TextField
                  required
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={this.state.description}
                  onChange={(e) => {
                    this.setState({ description: e.target.value });
                  }}
                  placeholder="Description..."
                  size="small"
                />
              </Grid>
              <Grid item sm={12} style={{ height: "10vh" }}>
                <b style={{ fontSize: "0.9em" }}>Cheque No #</b>
                <TextField
                  fullWidth
                  required
                  id="outlined-basic"
                  variant="outlined"
                  type="number"
                  value={this.state.cheque}
                  onFocus={() => {
                    if (this.state.cheque === 0) {
                      this.setState({ cheque: "" });
                    }
                  }}
                  onBlur={() => {
                    if (this.state.cheque === "") {
                      this.setState({ cheque: 0 });
                    }
                  }}
                  onChange={(e) => {
                    this.setState({ cheque: e.target.value });
                  }}
                  size="small"
                  placeholder="Cheque..."
                />
              </Grid>
              <Grid container item sm={12} style={{ height: "11vh" }}>
                <Grid item sm={6}>
                  <b class="debit" style={{ fontSize: "0.9em" }}>
                    {" "}
                    Debit -{" "}
                    <span style={{ color: "green" }}>
                      {this.numberWithCommas(this.state.debit)}
                    </span>
                  </b>
                  <TextField
                    required
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    type="number"
                    size="small"
                    value={this.state.debit}
                    class="debit form-control"
                    onFocus={() => {
                      if (this.state.debit === 0) {
                        this.setState({ debit: "" });
                      }
                    }}
                    onBlur={() => {
                      if (this.state.debit === "") {
                        this.setState({ debit: 0 });
                      }
                    }}
                    onChange={(e) => {
                      var debit = e.target.value;
                      if (this.state.credit > 0 && debit > 0) {
                        this.setState({ credit: 0 });
                      }
                      this.setState({ debit: e.target.value });
                    }}
                    placeholder="Debit..."
                  />
                </Grid>
                <Grid item sm={6} style={{ height: "10vh" }}>
                  <b class="credit" style={{ fontSize: "0.9em" }}>
                    Credit -{" "}
                    <span style={{ color: "red" }}>
                      {this.numberWithCommas(this.state.credit)}
                    </span>
                  </b>
                  <TextField
                    fullWidth
                    required
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    type="number"
                    value={this.state.credit}
                    class="credit form-control"
                    onFocus={() => {
                      if (this.state.credit === 0) {
                        this.setState({ credit: "" });
                      }
                    }}
                    onBlur={() => {
                      if (this.state.credit === "") {
                        this.setState({ credit: 0 });
                      }
                    }}
                    onChange={(e) => {
                      var credit = e.target.value;
                      if (this.state.debit > 0 && credit > 0) {
                        this.setState({ debit: 0 });
                      }
                      this.setState({ credit: e.target.value });
                    }}
                    placeholder="Credit..."
                  />
                </Grid>
              </Grid>
              <Grid container item sm={12} style={{ height: "6vh" }}>
                <Grid item sm={6}>
                  <Button
                    variant="contained"
                    size="small"
                    block
                    color="success"
                    type="submit"
                    style={{
                      color: "white",
                    }}
                    onClick={this.onSubmit}
                    disabled={this.state.credit > 0}
                  >
                    Add Debit
                  </Button>
                </Grid>
                <Grid item sm={6}>
                  <Button
                    variant="contained"
                    size="small"
                    block
                    type="submit"
                    color="error"
                    onClick={this.onSubmit}
                    disabled={this.state.debit > 0}
                  >
                    Add Credit
                  </Button>
                </Grid>
              </Grid>

              <Grid item sm={12} style={{ height: "10vh" }}>
                <b style={{ fontSize: "0.9em" }}>Upload File</b>
                <div>
                  <input
                    id="file-upload"
                    type="file"
                    accept="image/*,application/pdf"
                    onChange={this.handlePicture}
                  />
                </div>
              </Grid>
              <Grid item sm={12} style={{ height: "11vh" }}>
                <b style={{ fontSize: "0.9em", color: "rgb(32,55,100)" }}>
                  Remarks
                </b>
                <br />
                <Form.Control
                  style={{ width: "100%", borderRadius: 5 }}
                  as="textarea"
                  // rows="3"
                  value={this.state.remarks}
                  onChange={(e) => {
                    this.setState({ remarks: e.target.value });
                  }}
                />
              </Grid>
              <Grid item sm={12} style={{ height: "10vh" }}>
                <Button
                  variant="contained"
                  to="#"
                  size="md"
                  style={{ fontSize: "1.5em" }}
                  block
                  onClick={this.SaveJv}
                  fullWidth
                >
                  Save Record
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Modal
          open={this.state.add}
          onClose={() => this.setState({ add: false })}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {this.state.title}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {this.state.body}
            </Typography>
          </Box>
        </Modal>
      </>
    );
  }
}
export default JV;
