import React, { Component } from "react";
import { Form, Col, Row } from "react-bootstrap";
import "whatwg-fetch";

import { Card, CardHeader, CardBody } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import muiComma from "./commaFunction";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import ReactLoading from "react-loading";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@mui/icons-material/Add";
// ServerURL
import url from "src/serverURL.js";
import { forwardRef } from "react";
import { Grid, Container, Typography, Autocomplete } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import TextField from "@mui/material/TextField";
import EditSaleReturn from "./editSaleReturn";
import CreateSaleReturn from "./createSaleReturn";
import Print from "@mui/icons-material/Print";
import PDF from "./pdf";
import { PDFViewer } from "@react-pdf/renderer";

import MaterialTable from "material-table";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward style={{ color: "#fff", opacity: 1 }} {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

class SaleReturnReport extends Component {
  constructor() {
    super();
    this.state = {
      rows: [],
      call: () => {
        var columns = this.state.columns;
        var rows = this.state.rows;
        return { columns, rows };
      },
      id: 0,
      cash_accounts: [],
      bank_accounts: [],
      date_from: new Date().setMonth(new Date().getMonth() - 1),
      date_to: new Date(),
      final_flag: "",
      total_balance: 0,
      cash: true,
      bank: false,
      load: false,
      type: "customer",
      ac_title_data: null,
      all_accounts: {},
      original_record: [],
      body: "",
      title: "",
      opening_balance: 0,
      opening_flag: "",
      add: false,
      date: new Date(),
      amount: 0,
      discount: 0,
      net_cost: 0,
      submit: true,

      editFlag: false,
      editData: {},

      createFlag: false,

      invoice: {},
      printFlag: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }
  componentWillMount() {
    fetch(url + "inventry/jv/accounts", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status == 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((client) => {
        var cli = [];
        var accounts = {};
        client.map((acc) => {
          if (acc.type == "customer" || acc.type == "vendor") {
            cli.push(acc.name);
            var obj = {
              title: acc.name,
              type: acc.type,
              id: acc.id,
            };
            accounts[acc.name] = obj;
          } else {
            cli.push(acc.title);
            var obj = {
              title: acc.title,
              type: acc.type,
              id: acc.id,
            };
            accounts[acc.title] = obj;
          }
        });
        console.log(accounts);
        this.setState({ all_accounts: accounts });
        this.setState({ load: true });
        this.setState({ options: cli });
      });
  }

  Reload() {
    this.fetchData();
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  componentDidMount() {
    this.fetchData();
  }
  fetchData = () => {
    var from = new Date(this.state.date_from);
    from.setHours(0);
    var to = new Date(this.state.date_to);
    to.setHours(24);
    var data = {
      from: from,
      to: to,
      ac_id: this.state.ac_title_data,
    };

    fetch(url + "inventry/refund/allaggregate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((transactions) => {
        this.setState({ rows: transactions });
        var amount = 0;
        var discount = 0;
        var net_cost = 0;

        transactions.map((trans) => {
          amount = amount + trans.amount;
          discount = discount + trans.discount;
          net_cost = net_cost + trans.payable;
        });
        this.setState({ amount: amount });

        this.setState({ discount: discount });
        this.setState({ net_cost: net_cost });
        this.setState({ submit: false });
      });
  };
  onSubmit(e) {
    this.setState({ submit: true });
    e.preventDefault();
    this.fetchData();
  }
  Clear_Text = () => {
    try {
      const instance = this._typeahead_1.getInstance();
      instance.clear();
    } catch (e) {
      console.log(e);
    }
  };
  Accounts = () => {
    const truncateText = (text, maxChars) => {
      if (text.length > maxChars) {
        return text.slice(0, maxChars) + " ...";
      } else {
        return text;
      }
    };
    return (
      <div>
        <Autocomplete
          getOptionLabel={(option) => truncateText(option, 20)} // Truncating the option label
          caseSensitive={false}
          id="filtering-example"
          ignoreDiacritics={true}
          ref={(ref) => (this._typeahead_1 = ref)}
          onChange={(event, newValue) => {
            var id = this.state.all_accounts[newValue];
            console.log(id);
            this.setState({ ac_title_data: id });
          }}
          clearButton={true}
          size="small"
          options={this.state.options}
          placeholder="A/C Title..."
          renderInput={(params) => <TextField {...params} label="Search" />}
        />
      </div>
    );
  };
  getData() {
    var columns = this.state.columns;
    var rows = this.state.rows;
    var filter = this.original;
    return { columns, rows };
  }
  handleChangeFrom = (date) => {
    this.setState({
      date_from: date,
    });
  };
  handleChangeTo = (date) => {
    this.setState({
      date_to: date,
    });
  };
  editClose = () => {
    this.setState({ editFlag: false });
    this.Reload();
  };
  createClose = () => {
    this.setState({ createFlag: false });
    this.Reload();
  };

  // -->> Truncate Text after few Characters
  truncateText = (text, maxChars) => {
    if (text.length > maxChars) {
      return text.slice(0, maxChars) + " ...";
    } else {
      return text;
    }
  };
  render() {
    return (
      <Row>
        <Grid item md={12}>
          {this.state.editFlag ? (
            <EditSaleReturn
              editClose={this.editClose}
              editData={this.state.editData}
            />
          ) : null}
          {this.state.createFlag ? (
            <CreateSaleReturn createClose={this.createClose} />
          ) : null}
          <Card>
            <CardHeader></CardHeader>
            <CardBody className="all-icons">
              <Dialog
                open={this.state.printFlag}
                onClose={() => this.setState({ printFlag: false })}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
              >
                <DialogTitle id="dialog-title">Sale Return</DialogTitle>
                <DialogContent>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <PDFViewer width={"100%"} height="500" className="app">
                      <PDF
                        clientName={this.state.invoice.clientname}
                        invoiceNo={this.state.invoice.invoiceno}
                        address={this.state.invoice.address}
                        products={this.state.invoice.items}
                        discountTotal={this.state.invoice.discount}
                        gstTotal={this.state.invoice.gst_price}
                        netBillTotal={this.state.invoice.net_price}
                        payable={this.state.invoice.payable}
                        client={this.state.invoice.client}
                        date={this.state.invoice.date}
                        remarks={this.state.invoice.remarks}
                      />
                    </PDFViewer>
                  </Typography>
                </DialogContent>
              </Dialog>

              <Form onSubmit={this.onSubmit} className="Notifications">
                <Grid
                  container
                  controlId="exampleForm.ControlSelect2"
                  spacing={2}
                >
                  <Grid item sm={3}>
                    <Button
                      variant="contained"
                      size="md"
                      block
                      sx={{ marginTop: "1.8em" }}
                      onClick={() => {
                        this.setState({ createFlag: true });
                      }}
                      startIcon={<AddIcon />}
                    >
                      Create Sale Return
                    </Button>
                  </Grid>
                  <Grid item sm={1}></Grid>
                  <Grid item sm={2}>
                    <b>From :</b>
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        value={this.state.date_from}
                        maxDate={this.state.date_to}
                        onChange={this.handleChangeFrom}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item sm={2}>
                    <b>To :</b>
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        value={this.state.date_to}
                        maxDate={new Date()}
                        minDate={this.state.date_from}
                        onChange={this.handleChangeTo}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item sm={3}>
                    <b>A/C Title:</b>

                    {this.Accounts()}
                  </Grid>
                  <Grid item sm={1}>
                    <br />
                    <Button
                      variant="contained"
                      size="md"
                      block
                      type="submit"
                      disabled={this.state.date_from > this.state.date_to}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Form>
              <Grid
                style={{ marginTop: "1em" }}
                container
                controlId="formHorizontalEmail"
              >
                <Grid item md={12}>
                  <TableContainer component={Paper}>
                    <MaterialTable
                      title="All Sale Return Records"
                      icons={tableIcons}
                      columns={[
                        {
                          title: "Date",
                          field: "date",
                          type: "date",
                        },
                        {
                          title: "Invoice No.",
                          field: "invoiceno",
                        },
                        {
                          title: "Client Title",
                          field: "clientname",
                          render: (rowData) => {
                            const truncatedClientName = this.truncateText(
                              rowData.clientname,
                              18
                            );
                            return (
                              <Tooltip
                                title={
                                  <Typography fontSize="1.5em">
                                    {rowData.clientname}
                                  </Typography>
                                }
                              >
                                <span>{truncatedClientName}</span>
                              </Tooltip>
                            );
                          },
                        },
                        {
                          title: "Remarks",
                          field: "remarks",
                          render: (rowData) => {
                            const truncatedRemarks = this.truncateText(
                              rowData.remarks,
                              18
                            );
                            return (
                              <Tooltip
                                title={
                                  <Typography fontSize="1.5em">
                                    {rowData.remarks}
                                  </Typography>
                                }
                              >
                                <span>{truncatedRemarks}</span>
                              </Tooltip>
                            );
                          },
                        },
                        {
                          title: "Amount",
                          field: "amount",
                          render: (rowData) => muiComma(rowData.amount),
                        },
                        {
                          title: "Discount",
                          field: "discount",
                          render: (rowData) => muiComma(rowData.discount),
                        },
                        {
                          title: "GST",
                          field: "gst_price",
                          render: (rowData) => muiComma(rowData.gst_price),
                        },
                        {
                          title: "Payable",
                          field: "payable",
                          render: (rowData) => muiComma(rowData.net_price),
                        },
                      ]}
                      isLoading={this.state.submit}
                      data={this.state.rows}
                      options={{
                        headerStyle: {
                          backgroundColor: "#01579b",
                          color: "#FFF",
                          whiteSpace: "nowrap",
                          fontSize: "0.8em",
                        },

                        rowStyle: (rowData, index) => ({
                          backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#FFF",
                          height: "5em",
                          fontSize: "0.8em",
                        }),
                        exportButton: true,
                        exportAllData: true,
                        actionsColumnIndex: -1,
                        sorting: true,
                        search: true,
                        paging: true,
                        pageSize: 10,
                        pageSizeOptions: false,
                        showFirstLastPageButtons: false,
                      }}
                      actions={[
                        {
                          icon: () => <Edit />,
                          tooltip: "Edit",
                          onClick: (event, rowData) => {
                            console.log("Edit");
                            this.setState({ editData: rowData });
                            this.setState({ editFlag: true });
                          },
                        }
                
                      ]}
                    />
                  </TableContainer>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "0.5em" }}>
                <Grid item sm={6}></Grid>
                <Grid
                  item
                  sm={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <b class="Total Net Amount">Total Amount</b>
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    type="text"
                    value={this.numberWithCommas(this.state.amount.toFixed(2))}
                    style={{
                      fontWeight: "bold",
                    }}
                    required
                    placeholder="Debit..."
                  />
                </Grid>
                <Grid
                  item
                  sm={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <b class="Total Net Cost">Total Discount</b>
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    type="text"
                    value={this.numberWithCommas(
                      this.state.discount.toFixed(2)
                    )}
                    style={{
                      fontWeight: "bold",
                    }}
                    required
                    placeholder="Credit..."
                  />
                </Grid>
                <Grid
                  item
                  sm={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <b class="Total Profit">Total Payable</b>
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    type="text"
                    value={this.numberWithCommas(
                      this.state.net_cost.toFixed(2)
                    )}
                    style={{
                      fontWeight: "bold",
                    }}
                    required
                    placeholder="Total Balance..."
                  />
                </Grid>
              </Grid>

              <Modal
                open={this.state.alert}
                onClose={() => this.setState({ alert: false })}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {this.state.title}
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {this.state.body}
                  </Typography>
                </Box>
              </Modal>
            </CardBody>
          </Card>
        </Grid>
      </Row>
    );
  }
}
export default SaleReturnReport;
