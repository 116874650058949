import React, { Component } from "react";
import "whatwg-fetch";
import { Card, CardHeader, CardBody } from "reactstrap";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import ReactLoading from "react-loading";
import { forwardRef } from "react";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import MaterialTable from "material-table";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Grid, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import url from "src/serverURL";
import Tooltip from "@material-ui/core/Tooltip";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward style={{ color: "#fff", opacity: 1 }} {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

class trailBalance extends Component {
  constructor() {
    super();
    this.state = {
      columns: [
        {
          label: "ID",
          field: "id",
          sort: "asc",
        },
        {
          label: "Account Title",
          field: "title",
          sort: "asc",
        },
        {
          label: "Debit (OB)",
          field: "opening_debit",
          sort: "asc",
        },
        {
          label: "Credit (OB)",
          field: "opening_credit",
          sort: "asc",
        },
        {
          label: "Debit (During)",
          field: "process_debit",
          sort: "asc",
        },
        {
          label: "Credit (During)",
          field: "process_credit",
          sort: "asc",
        },
        {
          label: "Debit (CB)",
          field: "closing_debit",
          sort: "asc",
        },
        {
          label: "Credit (CB)",
          field: "closing_credit",
          sort: "asc",
        },
      ],
      rows: [],
      call: () => {
        var columns = this.state.columns;
        var rows = this.state.rows;
        return { columns, rows };
      },
      id: 0,
      cash_accounts: [],
      bank_accounts: [],
      date_from: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      date_to: new Date(),
      final_flag: "",
      total_opening_debit: 0,
      total_opening_credit: 0,
      total_process_debit: 0,
      total_process_credit: 0,
      total_closing_debit: 0,
      total_closing_credit: 0,
      cash: true,
      bank: false,
      type: "customer",
      ac_title_data: null,
      all_accounts: {},
      original_record: [],
      body: "",
      title: "",
      opening_balance: 0,
      opening_flag: "",
      add: false,
      date: new Date(),
      save: false,
      avGbyInvoice: {},
    };

    this.onSubmit = this.onSubmit.bind(this);
  }
  componentWillMount() {}
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  componentDidMount() {
    this.fetchData();
  }
  fetchData = () => {
    this.setState({ total_opening_credit: 0 });
    this.setState({ total_opening_debit: 0 });
    this.setState({ total_process_credit: 0 });
    this.setState({ total_process_debit: 0 });

    var from = new Date(this.state.date_from);
    from.setHours(0);
    var to = new Date(this.state.date_to);
    to.setHours(24);

    var data = {
      from: from,
      to: to,
    };
    this.setState({ save: true });

    data.clienttype = "";

    fetch(url + "inventry/reporting/tb_inventory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((transactions) => {
        var Opening = transactions.opening;
        var During = transactions.during;

        fetch(url + "inventry/reporting/trial", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((transactions) => {
            var the_rows = [];
            var total_opening_credit = 0;
            var total_opening_debit = 0;
            var total_process_debit = 0;
            var total_process_credit = 0;
            var total_closing_debit = 0;
            var total_closing_credit = 0;

            transactions.push({
              detail: { id: "C-1", name: "Cost of Sales", type: "COS" },
              opening: { credit: 0, debit: Opening.credit },
              process: { credit: 0, debit: During.credit },
            });

            transactions.filter((data) => {
              var credit = parseFloat(data.process.credit);
              var debit = parseFloat(data.process.debit);
              if (data.detail.name.includes("FEL")) {
              }
              var closing_debit = 0;
              var closing_credit = 0;
              var close =
                data.opening.debit +
                debit -
                Math.abs(data.opening.credit) -
                Math.abs(credit);

              if (isNaN(close)) {
                close = 0;
              }

              if (close > 0) {
                closing_debit = close;
              } else {
                closing_credit = Math.abs(close);
              }

              if (data.detail.id + "" == "A-1-1-4") {
                // Check Opening
                var opening = Opening.debit - Opening.credit;
                var openingDebit = 0;
                var openingCredit = 0;
                
                if (!isNaN(opening)) {
                  if (opening > 0) {
                    openingDebit = opening;
                  } else {
                    openingCredit = Math.abs(opening);
                  }
                } else {
                  openingDebit = 0;
                  openingCredit = 0;
                }
                var during = During.debit - During.credit;
                var duringDebit = 0;
                var duringCredit = 0;
                if (during > 0) {
                  duringDebit = during;
                } else {
                  duringCredit = Math.abs(during);
                }

                var close =
                  Math.abs(openingDebit) +
                  duringDebit -
                  openingCredit -
                  duringCredit;
                if (close > 0) {
                  closing_debit = close;
                } else {
                  closing_credit = Math.abs(close);
                }

                var put_data = {
                  id: data.detail.id,
                  title: data.detail.name,
                  type: data.detail.type,
                  opening_credit: this.numberWithCommas(
                    openingCredit.toFixed(2)
                  ),
                  opening_debit: this.numberWithCommas(openingDebit.toFixed(2)),
                  closing_debit: this.numberWithCommas(
                    closing_debit.toFixed(2)
                  ),
                  closing_credit: this.numberWithCommas(
                    closing_credit.toFixed(2)
                  ),

                  process_credit: this.numberWithCommas(
                    duringCredit.toFixed(2)
                  ),
                  process_debit: this.numberWithCommas(duringDebit.toFixed(2)),
                };

                total_opening_credit =
                  total_opening_credit + parseFloat(openingCredit);
                total_opening_debit =
                  total_opening_debit + parseFloat(openingDebit);
                total_process_debit =
                  total_process_debit + parseFloat(duringDebit);
                total_process_credit =
                  total_process_credit + parseFloat(duringCredit);
                total_closing_credit =
                  total_closing_credit + parseFloat(closing_credit);

                total_closing_debit =
                  total_closing_debit + parseFloat(closing_debit);
                the_rows.push(put_data);

                return;
              }
              var put_data = {
                id: data.detail.id,
                title: data.detail.name,
                type: data.detail.type,
                opening_credit: this.numberWithCommas(
                  data.opening.credit.toFixed(2)
                ),
                opening_debit:
                  data.opening.debit !== null
                    ? this.numberWithCommas(data.opening.debit.toFixed(2))
                    : 0,

                closing_debit: this.numberWithCommas(closing_debit.toFixed(2)),
                closing_credit: this.numberWithCommas(
                  closing_credit.toFixed(2)
                ),

                process_debit: this.numberWithCommas(
                  (data.process.debit - data.process.credit > 0
                    ? data.process.debit - data.process.credit
                    : 0
                  ).toFixed(2)
                ),
                process_credit: this.numberWithCommas(
                  (data.process.debit - data.process.credit > 0
                    ? 0
                    : Math.abs(data.process.debit - data.process.credit)
                  ).toFixed(2)
                ),
              };

              total_opening_credit =
                total_opening_credit + parseFloat(data.opening.credit);
              total_opening_debit =
                total_opening_debit + parseFloat(data.opening.debit);
              total_process_debit =
                total_process_debit +
                parseFloat(
                  data.process.debit - data.process.credit > 0
                    ? data.process.debit - data.process.credit
                    : 0
                );
              total_process_credit =
                total_process_credit +
                parseFloat(
                  data.process.debit - data.process.credit > 0
                    ? 0
                    : Math.abs(data.process.debit - data.process.credit)
                );
              total_closing_debit =
                total_closing_debit + parseFloat(closing_debit);
              total_closing_credit =
                total_closing_credit + parseFloat(closing_credit);
              the_rows.push(put_data);
            });

            this.setState({ rows: the_rows });
            this.setState({
              total_opening_credit: this.numberWithCommas(
                total_opening_credit.toFixed(2)
              ),
            });

            this.setState({
              total_opening_debit: this.numberWithCommas(
                total_opening_debit.toFixed(2)
              ),
            });

            this.setState({
              total_process_credit: this.numberWithCommas(
                total_process_credit.toFixed(2)
              ),
            });
            this.setState({
              total_process_debit: this.numberWithCommas(
                total_process_debit.toFixed(2)
              ),
            });
            this.setState({
              total_closing_credit: this.numberWithCommas(
                total_closing_credit.toFixed(2)
              ),
            });

            this.setState({
              total_closing_debit: this.numberWithCommas(
                total_closing_debit.toFixed(2)
              ),
            });
            this.setState({ save: false });
          });
      })
      .catch(() => {
        this.setState({ save: false });
      });
  };
  onSubmit(e) {
    e.preventDefault();
    // Validate date range
    const fromDate = new Date(this.state.date_from);
    const toDate = new Date(this.state.date_to);

    if (fromDate > toDate) {
      // Display an alert or handle the validation error accordingly
      alert("Date To cannot be earlier than Date From");
      return;
    }

    this.fetchData();
  }
  Clear_Text = () => {
    try {
      const instance = this._typeahead_1.getInstance();
      instance.clear();
    } catch (e) {
      console.log(e);
    }
  };

  getData() {
    var columns = this.state.columns;
    var rows = this.state.rows;
    var filter = this.original;
    return { columns, rows };
  }
  handleChangeFrom = (date) => {
    this.setState({
      date_from: date,
    });
  };
  handleChangeTo = (date) => {
    this.setState({
      date_to: date,
    });
  };

  truncateText = (text, maxChars) => {
    if (text.length > maxChars) {
      return text.slice(0, maxChars) + " ...";
    } else {
      return text;
    }
  };
  render() {
    return (
      <Grid container>
        <Grid md={12}>
          <Card>
            <CardHeader></CardHeader>
            <CardBody className="all-icons">
              <form onSubmit={this.onSubmit} className="Notifications">
                <Grid
                  container
                  spacing={2}
                  controlId="exampleForm.ControlSelect2"
                >
                  <Grid item sm={7}>
                    <br />
                    <h1 style={{ fontSize: "1.5em" }}>Trial Balance</h1>
                  </Grid>
                  <Grid item sm={2}>
                    <b>From :</b>
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        value={this.state.date_from}
                        onChange={(date_from) => {
                          this.setState({ date_from: date_from });
                        }}
                        maxDate={new Date()}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item sm={2}>
                    <b>To :</b>
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        value={this.state.date_to}
                        onChange={(date_to) => {
                          this.setState({ date_to: date_to });
                        }}
                        maxDate={new Date()}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item sm={1}>
                    <br />
                    <Button
                      variant="contained"
                      size="md"
                      block
                      type="submit"
                      // disabled={this.state.save}
                      disabled={this.state.date_from > this.state.date_to}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </form>
              <Grid
                style={{ marginTop: "1em" }}
                container
                controlId="formHorizontalEmail"
              >
                <Grid md={12}>
                  <MaterialTable
                    title="Trial Balance Records"
                    icons={tableIcons}
                    columns={[
                      // { title: "ID", field: "id" },
                      {
                        title: "Type",
                        field: "type",
                      },
                      {
                        title: "Account Title",
                        field: "title",
                        render: (rowData) => {
                          const truncatedAccount = this.truncateText(
                            rowData.title,
                            18
                          );
                          return (
                            <Tooltip
                              title={
                                <Typography fontSize="1.5em">
                                  {rowData.title}
                                </Typography>
                              }
                            >
                              <span>{truncatedAccount}</span>
                            </Tooltip>
                          );
                        },
                      },
                      {
                        title: " Debit (Opening)",
                        field: "opening_debit",
                        cellStyle: {
                          color: "red",
                          fontWeight: "bold",
                        },
                      },
                      {
                        title: "Credit (Opening)",
                        field: "opening_credit",
                        cellStyle: {
                          color: "blue",
                          fontWeight: "bold",
                        },
                      },
                      {
                        title: "Debit (During)",
                        field: "process_debit",
                        cellStyle: {
                          color: "red",
                          fontWeight: "bold",
                        },
                      },

                      {
                        title: "Credit (During)",
                        field: "process_credit",
                        cellStyle: {
                          color: "blue",
                          fontWeight: "bold",
                        },
                      },
                      {
                        title: "Debit (Closing)",
                        field: "closing_debit",
                        cellStyle: {
                          color: "red",
                          fontWeight: "bold",
                        },
                      },
                      {
                        title: "Credit (Closing)",
                        field: "closing_credit",
                        cellStyle: {
                          color: "blue",
                          fontWeight: "bold",
                        },
                      },
                    ]}
                    isLoading={this.state.save}
                    data={this.state.rows}
                    options={{
                      headerStyle: {
                        backgroundColor: "#01579b",
                        color: "#FFF",
                        whiteSpace: "nowrap",
                        fontSize: "0.8em",
                      },
                      rowStyle: (rowData, index) => ({
                        backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#FFF",
                        height: "5em",
                        fontSize: "0.8em",
                      }),
                      search: true,
                      grouping: false,
                      exportButton: true,
                      exportAllData: true,
                      selection: false,
                      showTitle: true,
                      paging: true,
                      pageSize: 10,
                      pageSizeOptions: false,
                      showFirstLastPageButtons: false,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                controlId="exampleForm.ControlSelect2"
                style={{ marginTop: "0.5em" }}
              >
                <Grid item sm={2}>
                  <b class="debit">Opening Debit</b>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    value={this.state.total_opening_debit}
                    class="debit form-control"
                    required
                    placeholder="Debit..."
                    disabled
                  />
                </Grid>
                <Grid item sm={2}>
                  <b class="credit">Opeing Credit</b>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    value={this.state.total_opening_credit}
                    class="credit form-control"
                    required
                    placeholder="Credit..."
                    disabled
                  />
                </Grid>
                <Grid item sm={2}>
                  <b class="debit">During Period Debit</b>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    value={this.state.total_process_debit}
                    class="debit form-control"
                    required
                    placeholder="Debit..."
                    disabled
                  />
                </Grid>
                <Grid item sm={2}>
                  <b class="credit">During Period Credit</b>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    value={this.state.total_process_credit}
                    class="credit form-control"
                    required
                    placeholder="Credit..."
                    disabled
                  />
                </Grid>
                <Grid item sm={2}>
                  <b class="debit">Closing Debit</b>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    value={this.state.total_closing_debit}
                    class="debit form-control"
                    required
                    placeholder="Debit..."
                    disabled
                  />
                </Grid>
                <Grid item sm={2}>
                  <b class="credit">Closing Credit</b>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    value={this.state.total_closing_credit}
                    class="credit form-control"
                    required
                    placeholder="Credit..."
                    disabled
                  />
                </Grid>
              </Grid>
              <Modal
                open={this.state.add}
                onClose={() => this.setState({ add: false })}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {this.state.title}
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {this.state.body}
                  </Typography>
                </Box>
              </Modal>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    );
  }
}
export default trailBalance;
