import * as React from "react";
import { forwardRef, useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import { makeStyles } from "@mui/styles";

// ServerURL
import url from "src/serverURL.js";
const useStyles = makeStyles({
  dialog: {
    position: "fixed",
    top: "0",
  },
});

// --
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AddItem({
  handleClick,
  handleClickError,
  handleCloseModal,
  isOpen,
}) {
  const [form, setForm] = useState({});
  const [category, setCategories] = useState([]);
  // State for modal
  const [openModal, setOpenModal] = useState(false);

  // Function to open modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const classes = useStyles();

  /// -->> Function to manage all the Inputs
  const handleInputChange = (fieldName, value) => {
    const sanitizedValue = value
      .replace(/^\s+/g, "")
      .replace(/\s+/g, " ")
      .replace(/[^\w\s@.]/gi, "");

    setForm({
      ...form,
      [fieldName]: sanitizedValue,
    });
  };
  const handleNumbersOnly = (fieldName, value) => {
    const sanitizedValue = value.replace(/[^\d-]/g, "");
  
    setForm({
      ...form,
      [fieldName]: sanitizedValue,
    });
  };
  

  // -->> Date Function
  const getDate = () => {
    return `${new Date().getDate()}/${
      new Date().getMonth() + 1
    }/${new Date().getFullYear()}`;
  };

  const submit = () => {
    if (form.name === undefined || form.name.trim() === "") {
      handleClickError("Invalid Client Name");
      return;
    }
    if (form.type === undefined || form.type.trim() === "") {
      handleClickError("Invalid Client Type");
      return;
    }
   if(form.email != undefined && form.email.trim() != ""){
    // Email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(form.email.trim())) {
      handleClickError("Invalid Email Format");
      return;
    }
   }


    if (form.address === undefined || form.address.trim() === "") {
      handleClickError("Invalid Client Address");
      return;
    }
   
    var data = {
      name: form.name == undefined ? "" : form.name,
      mobile: form.mobile == undefined ? "" : form.mobile,
      type: form.type == undefined ? "" : form.type,
      email: form.email == undefined ? "" : form.email,
      ntn: form.ntn == undefined ? "" : form.ntn,
      stn: form.stn == undefined ? "" : form.stn,
      address: form.address == undefined ? "" : form.address,
      debit: form.debit == undefined ? "" : form.debit,
      credit: form.credit == undefined ? "" : form.credit,
      userId: localStorage.getItem("id"),
    };
    fetch(url + "inventry/client", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status == 400) {
        handleClickError("Client Creation Error!");
        return;
      }
      if (res.status == 409) {
        handleClickError("Email Already Registered!");
        return;
      }
      // setEditLoading(false);
      setForm({});
      handleClick("Client Added Successfully !");
      handleCloseModal();
    });
  };

  useEffect(() => {
    fetch(url + "inventry/client", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((categories) => {
        const box = categories.filter((data) => ({
          name: data.name,
          id: data.id,
          type: data.type,
        }));
        setCategories(box);
      });
  }, []);

  return (
    <>
      {/* Existing code... */}
      {/* <div style={{ padding: 10 }}>
        <Button variant="contained" onClick={handleOpenModal}>
          Add New Item
        </Button>
      </div> */}

      <Dialog
        // open={openModal}
        open={isOpen}
        // onClose={handleCloseModal}
        // fullWidth
        // maxWidth="lg"
        classes={{ paper: classes.dialog }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Add New Client</DialogTitle>
        <DialogContent style={{ maxWidth: "500px" }}>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <TextField
                label="Date"
                variant="filled"
                disabled
                value={getDate()}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="filled-"
                label="Name"
                variant="filled"
                value={form.name === undefined ? "" : form.name}
                required
                inputProps={{ maxLength: 32 }}
                onChange={(e) => handleInputChange("name", e.target.value)}
              />
            </Grid>
            <Grid item md={6}>
              <FormControl sx={{ minWidth: 215 }}>
                <TextField
                  margin="normal"
                  id="outlined-select-currency"
                  select
                  label="Select Type"
                  variant="filled"
                  required
                  style={{ marginTop: 0 }}
                  value={form.type}
                  onChange={(e) => handleInputChange("type", e.target.value)}
                  // autoFocus
                >
                  {/* <MenuItem value={undefined}>Select</MenuItem> */}
                  <MenuItem disabled value={"select"}>
                    Select Type
                  </MenuItem>
                  <MenuItem value={"vendor"}>Vendor</MenuItem>
                  <MenuItem value={"customer"}>Customer</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <TextField
                id="filled-basic"
                label="Email"
                variant="filled"
                type="email"
                value={form.email === undefined ? "" : form.email}
                onChange={(e) => handleInputChange("email", e.target.value)}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="filled-basic"
                label="Mobile"
                variant="filled"
                value={form.mobile === undefined ? "" : form.mobile}
                onChange={(e) => handleNumbersOnly("mobile", e.target.value)}
                inputProps={{ maxLength: 13 }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="filled-basic"
                label="NTN No."
                variant="filled"
                value={form.ntn === undefined ? "" : form.ntn}
                onChange={(e) => handleNumbersOnly("ntn", e.target.value)}
                inputProps={{ maxLength: 9 }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="filled-basic"
                label="STRN No."
                variant="filled"
                value={form.stn === undefined ? "" : form.stn}
                onChange={(e) => handleNumbersOnly("stn", e.target.value)}
                inputProps={{ maxLength: 13 }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="filled-basic"
                variant="filled"
                label="Address"
                required
                value={form.address === undefined ? "" : form.address}
                onChange={(e) => handleInputChange("address", e.target.value)}
                inputProps={{ maxLength: 32 }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="filled-basic"
                label="Debit"
                variant="filled"
                value={form.debit === undefined ? "" : form.debit}
                onChange={(e) =>
                  handleNumbersOnly("debit", e.target.value)
                }
                inputProps={{ maxLength: 8 }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="filled-basic"
                label="Credit"
                variant="filled"
                value={form.credit === undefined ? "" : form.credit}
                onChange={(e) =>
                  handleNumbersOnly("credit", e.target.value)
                }
                inputProps={{ maxLength: 8 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginRight: "14px" }}>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={submit} variant="contained">
            Add Client
          </Button>
        </DialogActions>
      </Dialog>

      {/* Existing code... */}
    </>
  );
}
