import React, { Component, createRef } from "react";
import { Form, Col, Row } from "react-bootstrap";
import "whatwg-fetch";
import { Card, CardHeader, CardBody } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import ReactLoading from "react-loading";
import { forwardRef } from "react";
import Print from "@mui/icons-material/Print";
import {
  Grid,
  Container,
  Typography,
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import url from "../../serverURL.js";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import VisibilityIcon from "@mui/icons-material/Visibility";
import PDF from "./pdf";
import { PDFViewer } from "@react-pdf/renderer";
import TextField from "@mui/material/TextField";
import EditJV from "./editJV";
import CreateJV from "./createJV";
import ViewJV from "./viewJvImage.js";

import muiComma from "./commaFunction";
import MaterialTable from "material-table";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward style={{ color: "#fff", opacity: 1 }} {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

class JVManage extends Component {
  constructor() {
    super();
    this.state = {
      rows: [],
      call: () => {
        var columns = this.state.columns;
        var rows = this.state.rows;
        return { columns, rows };
      },
      id: 0,
      cash_accounts: [],
      bank_accounts: [],
      date_from: new Date().setMonth(new Date().getMonth() - 1),
      date_to: new Date(),
      final_flag: "",
      total_balance: 0,
      cash: true,
      bank: false,
      load: true,
      type: "customer",
      ac_title_data: null,
      all_accounts: {},
      original_record: [],
      body: "",
      title: "",
      opening_balance: 0,
      opening_flag: "",
      add: false,
      date: new Date(),
      amount: 0,
      discount: 0,
      net_cost: 0,
      submit: true,
      editFlag: false,
      editData: {},
      createFlag: false,
      invoice: {},
      viewFlag: false,
      printFlag: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.downloadCsv = this.downloadCsv.bind(this);
    this.tableRef = createRef();
  }
  
  downloadCsv(data, fileName){
    const finalFileName = fileName.endsWith(".csv")
      ? fileName
      : `${fileName}.csv`;
    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
    a.setAttribute("download", finalFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  componentWillMount() {}
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  componentDidMount() {
    this.fetchData();
  }

  Reload = () => {
    console.log("Reload function is called");
    this.fetchData();
  };

  fetchData = () => {
    var from = new Date(this.state.date_from);
    from.setHours(0);
    var to = new Date(this.state.date_to);
    to.setHours(24);
    var data = {
      from: from,
      to: to,
      ac_id: this.state.ac_title_data,
    };

    fetch(url + "inventry/jv/report", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((transactions) => {
        var amount = 0;
        const jv = transactions.map((jv) => {
          amount = amount + jv.credit;
          var acc = jv.transactions.map((i) => i.ac_title);

          return {
            accounts: acc.toLocaleString(),

            ...jv,
          };
        });
        this.setState({ rows: jv });
        this.setState({ amount: amount });
        this.setState({ submit: false });
      });
  };
  onSubmit(e) {
    this.setState({ submit: true });

    e.preventDefault();
    this.fetchData();
  }
  Clear_Text = () => {
    try {
      const instance = this._typeahead_1.getInstance();
      instance.clear();
    } catch (e) {
      console.log(e);
    }
  };
  Accounts = () => {
    const truncateText = (text, maxChars) => {
      if (text.length > maxChars) {
        return text.slice(0, maxChars) + " ...";
      } else {
        return text;
      }
    };
    return (
      <div>
        <Autocomplete
          caseSensitive={false}
          id="filtering-example"
          ignoreDiacritics={true}
          ref={(ref) => (this._typeahead_1 = ref)}
          onChange={(event, newValue) => {
            var id = this.state.all_accounts[newValue];
            console.log(id);
            this.setState({ ac_title_data: id });
          }}
          clearButton={true}
          options={this.state.options}
          getOptionLabel={(option) => truncateText(option, 20)} // Truncating the option label
          placeholder="A/C Title..."
          size="small"
          renderInput={(params) => <TextField {...params} label="Search" />}
        />
      </div>
    );
  };
  getData() {
    var columns = this.state.columns;
    var rows = this.state.rows;
    var filter = this.original;
    return { columns, rows };
  }
  handleChangeFrom = (date) => {
    this.setState({
      date_from: date,
    });
  };
  handleChangeTo = (date) => {
    this.setState({
      date_to: date,
    });
  };
  editClose = () => {
    this.setState({ editFlag: false });
    this.Reload();
  };
  viewClose = () => {
    this.setState({ viewFlag: false });
  };
  createClose = () => {
    this.setState({ createFlag: false });
    this.Reload();
  };

  truncateText = (text, maxChars) => {
    if (text.length > maxChars) {
      return text.slice(0, maxChars) + " ...";
    } else {
      return text;
    }
  };
  render() {
    return (
      <Row>
        <Grid item md={12}>
          {this.state.editFlag ? (
            <EditJV editClose={this.editClose} editData={this.state.editData} />
          ) : null}
          {this.state.createFlag ? (
            <CreateJV createClose={this.createClose} />
          ) : null}
          {this.state.viewFlag ? (
            <ViewJV viewClose={this.viewClose} viewData={this.state.viewData} />
          ) : null}
          <Card>
            <CardHeader></CardHeader>
            <CardBody className="all-icons">
              <Dialog
                open={this.state.printFlag}
                onClose={() => this.setState({ printFlag: false })}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <DialogTitle id="dialog-title">Journal Voucher</DialogTitle>
                <DialogContent>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <PDFViewer height="1000" width="100%" className="app">
                      <PDF
                        accounts={this.state.invoice.transactions}
                        accountTitle={this.state.invoice.accounts}
                        invoiceNo={this.state.invoice.id}
                        paymentMethod={this.state.invoice.payment_method}
                        paymentTitle={this.state.invoice.payment_title}
                        advanceTax={this.state.invoice.advance_tax}
                        chequeNo={this.state.invoice.cheque}
                        totalAmount={this.state.invoice.amount}
                        paymentId={this.state.invoice.payment_id}
                        date={this.state.invoice.date}
                        client={this.state.invoice.client}
                        remarks={this.state.invoice.remarks}
                      />
                    </PDFViewer>
                  </Typography>
                </DialogContent>
              </Dialog>

              <Form onSubmit={this.onSubmit} className="Notifications">
                <Grid
                  container
                  controlId="exampleForm.ControlSelect2"
                  spacing={2}
                >
                  <Grid item sm={2}>
                    <br />
                    <Button
                      variant="contained"
                      size="md"
                      block
                      onClick={() => {
                        this.setState({ createFlag: true });
                      }}
                      startIcon={<AddIcon />}
                    >
                      Create JV
                    </Button>
                  </Grid>
                  <Grid item sm={5}></Grid>
                  <Grid item sm={2}>
                    <b>From :</b>
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        value={this.state.date_from}
                        onChange={this.handleChangeFrom}
                        maxDate={this.state.date_to}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item sm={2}>
                    <b>To :</b>
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        value={this.state.date_to}
                        onChange={this.handleChangeTo}
                        maxDate={new Date()}
                        minDate={this.state.date_from}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item sm={1}>
                    <br />
                    <Button
                      variant="contained"
                      size="md"
                      block
                      type="submit"
                      disabled={this.state.date_from > this.state.date_to}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Form>
              <Grid
                container
                style={{ marginTop: "1em" }}
                controlId="formHorizontalEmail"
              >
                <Grid item md={12}>
                  <TableContainer component={Paper}>
                    <MaterialTable
                      title="All JV Records"
                      tableRef={this.tableRef}
                      icons={tableIcons}
                      columns={[
                        {
                          title: "Date",
                          field: "date",
                          type: "date",
                        },
                        {
                          title: "JV No.",
                          field: "id",
                        },
                        {
                          title: "Accounts",
                          field: "accounts",
                          render: (rowData) => {
                            const truncatedAccounts = this.truncateText(
                              rowData.accounts,
                              18
                            );
                            return (
                              <Tooltip
                                title={
                                  <Typography fontSize="1.5em">
                                    {rowData.accounts}
                                  </Typography>
                                }
                              >
                                <span>{truncatedAccounts}</span>
                              </Tooltip>
                            );
                          },
                        },
                        {
                          title: "Amount",
                          field: "credit",
                          render: (rowData) => muiComma(rowData.credit),
                        },
                        {
                          title: "Remarks",
                          field: "remarks",
                          render: (rowData) => {
                            const truncatedRemarks = this.truncateText(
                              rowData.remarks,
                              18
                            );
                            return (
                              <Tooltip
                                title={
                                  <Typography fontSize="1.5em">
                                    {rowData.remarks}
                                  </Typography>
                                }
                              >
                                <span>{truncatedRemarks}</span>
                              </Tooltip>
                            );
                          },
                        },
                      ]}
                      isLoading={this.state.submit}
                      data={this.state.rows}
                      options={{
                        headerStyle: {
                          backgroundColor: "#01579b",
                          color: "#FFF",
                          whiteSpace: "nowrap",
                          fontSize: "0.8em",
                        },
                         
                      exportCsv: (columns, data) => {
                        // Turn headers into array of strings
                        const headerRow = columns.map((col) => {
                          if (typeof col.title === "object") {
                            // I am not sure what props the Translate component exposes
                            // but you would need to change `text` in `col.title.props.text`
                            // to whatever prop you need.
                            return col.title.props.text;
                          }
                          return col.title;
                        });
            
                        // Turn data into an array of string arrays, without the `tableData` prop
            
                        const dataRows = data.map(({ tableData, ...row }) => {
                         
            
                          const d = new Date(row.date);
                          let mm = d.getMonth()+1;
                          let dd = d.getDate();
                          let yyyy = d.getFullYear();
            
                          row = {
                            date: dd + "/" + mm + "/" + yyyy,
                            id:row.id,
                            accounts:(row.accounts+"").replace(/,/g, ';'),
                            credit:(row.credit+"").replace(/,/g, ''),
                            remarks:(row.remarks+"").replace(/,/g, '')
                          };
            
                          return Object.values(row);
                        });
             
                        // Aggregate header data and 'body' data
                        // Mirror default export behavior by joining data via
                        // the delimiter specified within material table (by default comma delimited)
                        const { exportDelimiter } = this.tableRef.current.props.options;
                        const delimiter = exportDelimiter ? exportDelimiter : ",";
                        const csvContent = [headerRow, ...dataRows]
                          .map((e) => e.join(delimiter))
                          .join("\n");
            
                        // This mirrors the default export behavior where the
                        // exported file name is the table title.
                        const csvFileName = this.tableRef.current.props.title;
            
                        // Allow user to download file as .csv
                        this.downloadCsv(csvContent, csvFileName);
                      },
                    

                        rowStyle: (rowData, index) => ({
                          backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#FFF",
                          height: "5em",
                          fontSize: "0.8em",
                        }),
                        exportAllData: true,
                        exportButton: true,
                        actionsColumnIndex: -1,
                        sorting: true,
                        search: true,
                        paging: true,
                        pageSize: 10,
                        pageSizeOptions: false,
                        showFirstLastPageButtons: false,
                      }}
                      actions={[
                        {
                          icon: () => <VisibilityIcon style={{ padding: 0 }} />,
                          tooltip: "View JV Image",
                          onClick: (event, rowData) => {
                            console.log("View");
                            this.setState({ viewData: rowData });
                            this.setState({ viewFlag: true });
                          },
                        },
                        {
                          icon: () => <Edit />,
                          tooltip: "Edit",
                          onClick: (event, rowData) => {
                            console.log("Edit");
                            this.setState({ editData: rowData });
                            this.setState({ editFlag: true });
                          },
                        },
                        {
                          icon: () => <Print />,
                          tooltip: "Print",
                          onClick: (event, rowData) => {
                            console.log(rowData);
                            console.log(
                              "Clients are",
                              this.state.invoice.client
                            );
                            this.setState({ invoice: rowData });
                            this.setState({ printFlag: true });
                          },
                        },
                        {
                          icon: () => <DeleteOutline />,
                          tooltip: "Delete",
                          onClick: (event, rowData) => {
                            if (
                              window.confirm(
                                "Are you sure to delete this JV ?"
                              ) == true
                            ) {
                              var data = {
                                id: rowData.id,
                              };

                              fetch(url + "inventry/jv", {
                                method: "DELETE",
                                headers: {
                                  "Content-Type": "application/json",
                                },
                                body: JSON.stringify(data),
                              }).then((res) => {
                                if (res.status == 200) {
                                  alert("Deleted");
                                  this.Reload();
                                } else {
                                  alert("Something went wrong!");
                                }
                              });
                            }
                          },
                        },
                      ]}
                    />
                  </TableContainer>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "0.5em" }}>
                <Grid item sm={6}></Grid>
                <Grid
                  item
                  sm={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                ></Grid>
                <Grid
                  item
                  sm={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                ></Grid>
                <Grid
                  item
                  sm={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <b class="Total Profit">Total Amount</b>
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    type="text"
                    value={this.numberWithCommas(this.state.amount.toFixed(2))}
                    style={{
                      fontWeight: "bold",
                    }}
                    required
                    placeholder="Total Amount..."
                  />
                </Grid>
              </Grid>

              <Modal
                open={this.state.alert}
                onClose={() => this.setState({ alert: false })}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {this.state.title}
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {this.state.body}
                  </Typography>
                </Box>
              </Modal>
            </CardBody>
          </Card>
        </Grid>
      </Row>
    );
  }
}
export default JVManage;
